const qrCodeConfig = {
  width: 300,
  height: 300,
  data: '',
  margin: 0,
  qrOptions: {
    typeNumber: '0',
    mode: 'Byte',
    errorCorrectionLevel: 'H',
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 3,
  },
  dotsOptions: {
    type: 'extra-rounded',
    color: '#002F6E',
    gradient: null,
  },
  backgroundOptions: {
    color: '#FFF',
  },
  image: '',
  dotsOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: '#6A1A4C',
      color2: '#6A1A4C',
      rotation: '0',
    },
  },
  cornersSquareOptions: {
    type: 'extra-rounded',
    color: '#37D4AF',
  },
  cornersSquareOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: '#000',
      color2: '#000',
      rotation: '0',
    },
  },
  cornersDotOptions: {
    type: 'dot',
    color: '#37D4AF',
  },
  cornersDotOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: '#000',
      color2: '#000',
      rotation: '0',
    },
  },
  backgroundOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: '#FFF',
      color2: '#FFF',
      rotation: '0',
    },
  },
};

export default qrCodeConfig;
