<template>
  <v-container class="pa-0" fill-height fluid>
    <v-row class="fill-height" no-gutters>
      <v-col cols="12">
        <v-container class="d-flex align-start fill-height">
          <backButton />
          <v-row>
            <v-col cols="12">
              <v-card width="500px" class="pl-15 mb-16" elevation="0">
                <v-card-title class="login-title">
                  {{ t('Title_.page.loginPflegeApp') }}
                </v-card-title>
                <v-card-subtitle class="login-subtitle mb-12">
                  {{ t('Title_.subTitle.loginQrInstructions[0]') }}
                  <strong>{{
                    t('Title_.subTitle.loginQrInstructions[1]')
                  }}</strong>
                  {{ t('Title_.subTitle.loginQrInstructions[2]') }}
                </v-card-subtitle>
                <v-card-text class="d-flex align-center justify-center">
                  <template
                    v-if="
                      authenticationStore.verificationUriComplete !== null &&
                      authenticationStore.isAuthenticationLoading === false
                    "
                  >
                    <div>
                      <v-img
                        id="qrCode"
                        width="300"
                        data-testid="qr-code"
                        height="300"
                      />
                      <div class="mt-2 d-flex align-center">
                        <v-progress-circular
                          :rotate="-90"
                          :size="15"
                          :width="2"
                          :value="percentageValue"
                          color="blueRegular"
                        >
                        </v-progress-circular>
                        <span
                          data-testid="qrcode-click"
                          class="ml-1"
                          :disabled="true"
                          :style="{
                            cursor: percentageValue <= 0 ? 'pointer' : '',
                          }"
                          @click="percentageValue <= 0 ? getNewQrCode() : null"
                        >
                          {{ t('Action_.buttons.newQrCode') }}:
                          {{ convertValueToTime(percentageValue) }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      <v-img
                        lazy-src="@/assets/authentication/loading_qr_code.png"
                        width="300"
                        height="300"
                      >
                        <template #placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import QRCodeStyling from 'qr-code-styling';
import kenbiLogo from '@/assets/navigationDrawer/Kenbi_Logo.svg';
import qrCodeLocalImg from '@/assets/authentication/qr-code-local-img.svg';
import qrCodeDevImg from '@/assets/authentication/qr-code-dev-img.svg';
import qrCodeStgImg from '@/assets/authentication/qr-code-stg-img.svg';
import qrCodeConfig from './qrCodeConfig.js';
import backButton from '../back-button.vue';

import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { useAuthenticationStore } from '@/store/pinia/authentication.store.js';
import { ref, onBeforeUnmount, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import languages from '@/components/authentication/locales/languages';

const { trackPage } = amplitudeComposable();
const authenticationStore = useAuthenticationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local Variables ---
const countdownStop = ref(true);
const interval = ref({});
const percentageValue = ref(100);

// --- Methods ---
const getNewQrCode = async () => {
  // const t = this.$t.bind(this);
  // const toastParams = {
  //   type: 'success',
  //   messages: {
  //     error: t('Msg_.failGenerateQrCode'),
  //     success: t('Msg_.successfullyGenerateQrCode'),
  //   },
  // };
  // this.$root.message.setToastParams(toastParams);

  try {
    await authenticationStore.getQrCode();
    // this.$root.message.showToastNotification();
    countdownStop.value = false;
    percentageValue.value = authenticationStore.expiresIn;

    setTimeout(() => {
      getQrCodeAccessApprovalStatus();
    }, 5000);
  } catch (error) {
    console.log(error);
  }

  styleQR(authenticationStore.verificationUriComplete);
};

const getQrCodeAccessApprovalStatus = async () => {
  try {
    let res = await authenticationStore.getQrCodeAccessApproval();
    if (!res.error) {
      window.location.href = `${process.env.VUE_APP_LOGIN_API}`;
    }
  } catch (err) {
    console.log(err);
  }
};

const countDown = () => {
  interval.value = setInterval(() => {
    if (!countdownStop.value) {
      if (percentageValue.value <= 0) {
        countdownStop.value = true;
        percentageValue.value = 0;
      } else {
        percentageValue.value -= 1;
      }
    }
  }, 1000);
};

const convertValueToTime = (value) => {
  if (value != 0) {
    const sec = parseInt(value, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
  }
  return 'Ready';
};

const styleQR = (url) => {
  let selectedImage;
  selectedImage = getEnvImg(process.env.VUE_APP_LOGIN_API);
  qrCodeConfig.data = url;
  qrCodeConfig.image = selectedImage;
  const qrCode = new QRCodeStyling(qrCodeConfig);
  qrCode.append(document.getElementById('qrCode'));
};

const getEnvImg = (env) => {
  if (env.includes('local')) {
    return qrCodeLocalImg;
  } else if (env.includes('dev')) {
    return qrCodeDevImg;
  } else if (env.includes('stg')) {
    return qrCodeStgImg;
  } else if (env.includes('prd')) {
    return kenbiLogo;
  }
  return null;
};

trackPage();

// --- Lifecycle hooks ---
onBeforeMount(() => {
  countDown();
  getNewQrCode();
});

onBeforeUnmount(() => {
  clearInterval(interval.value);
  authenticationStore.verificationUriComplete = null;
});
</script>
